<template>
<div>
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="home" to="/home" />
      <q-breadcrumbs-el label="Jobs" to="/jobs/list/1" />
      <q-breadcrumbs-el label="Search" />
    </q-breadcrumbs>

  <q-card class="q-my-lg">
      <q-card-section class="q-pa-sm bg-primary text-white">
          <h6 class="q-my-none">Search Terms
            <q-btn size="sm" icon-right="close" class="bg-white text-red" rounded no-caps @click="clearFilter">Clear All</q-btn>
          </h6>
      </q-card-section>

      <q-card-section class="q-pb-none">
        <div class="q-pa-md">
            Use this tool to filter the list of jobs. The results will match all the keywords you specify and any one of each of the other items you define.  Therefore it would return a list of all jobs that match all your keywords, in any one of the states you specify with at least any one skill you specify.
        </div>
      </q-card-section>

      <q-separator />

      <q-card-section class="q-pa-sm">
        <div v-if="postFilter && postFilter.terms && Array.isArray(postFilter.terms)">
          <q-chip v-for="term in postFilter.terms" :key="term"  square color="primary" text-color="white">
            Keyword &nbsp; &nbsp;
            <q-chip size=".80em" removable @remove="removeTerm(term)">{{term}}&nbsp;&nbsp;</q-chip>
          </q-chip>

          <q-separator v-if="postFilter.terms.length > 0" />
        </div>

        <div v-if="postFilter && postFilter.stateCodes && Array.isArray(postFilter.stateCodes)">
          <q-chip v-for="stateCode in postFilter.stateCodes" :key="stateCode"  square color="secondary" text-color="white">
            State &nbsp; &nbsp;
            <q-chip size=".80em" removable @remove="removeStateCode(stateCode)">{{stateCode}}&nbsp;&nbsp;</q-chip>
          </q-chip>

          <q-separator v-if="postFilter.stateCodes.length > 0" />
        </div>

        <div v-if="postFilter && postFilter.skills && Array.isArray(postFilter.skills)">
          <q-chip v-for="skill in postFilter.skills" :key="skill"  square color="positive" text-color="white">
            Skill &nbsp; &nbsp;
            <q-chip size=".80em" removable @remove="removeSkill(skill)">{{skill}}&nbsp;&nbsp;</q-chip>
          </q-chip>
        </div>

      </q-card-section>

      <q-card-section class="q-pa-sm row q-gutter-md">
          <div class="col-shrink">
            <q-input v-model="newTerm" dense label="Keyword" @keyup.enter="addTerm">
                <template v-slot:append>
                    <q-icon name="add" @click="addTerm" />
                </template>
            </q-input>
          </div>
          <div class="col-auto" style="min-width: 100px;">
            <q-select v-model="newSkill" dense label="Skill" :options="filteredSkills" option-label="_id" option-value="_id" emit-value @input="addSkill">
            </q-select>
          </div>

          <div class="col-auto" style="min-width: 100px;">
            <q-select v-model="newStateCode" dense label="State Code" :options="filteredStateCodes" option-label="_id" option-value="_id" emit-value @input="addStateCode">
            </q-select>
          </div>


          <div class="col-grow">
          </div>
      </q-card-section>


  </q-card>

  <div class="row justify-between items-end q-mb-sm q-mt-md">
    <div class="col full-height text-h5">
      {{numRecs}} Job<span v-if="numRecs != 1">s</span> matched of
        of {{numTotalRecs}}
    </div>

    <q-pagination
      :value="currentPage"
      :max="numPages"
      :max-pages="5"
      :ellipses="false"
      :boundary-links="true"
      :boundary-numbers="false"
      :direction-links="true"
      @input="currentPage != $event ? gotoPage($event) : null"
    >
    </q-pagination>
  </div>

  <table class="table table-striped table-sm full-width">
    <job-list-item v-for="post in recs" :key="post._id" :post="post"></job-list-item>
  </table>

  <q-banner v-if="numJobs == 0" class="text-white bg-red">
      <div class="text-h6">
          To get more results, add <span v-if="postFilter.skills.length > 0">more skills</span>
          <span v-if="postFilter.stateCodes.length > 0">
          <span v-if="postFilter.skills.length > 0">, and/or </span>
              more states
          </span>
      </div>
  </q-banner>


  <div class="row justify-end items-end q-mb-sm q-mt-md">

    <q-pagination
      :value="currentPage"
      :max="numPages"
      :max-pages="5"
      :ellipses="false"
      :boundary-links="true"
      :boundary-numbers="false"
      :direction-links="true"
      @input="gotoPage"
    >
    </q-pagination>
  </div>


</div>
</template>

<script>
// import { PostActionTypes } from "../../store/module-post/action-types";
import jobModule from "../../store";
import { mapState } from 'vuex';

export default {
  data() {
    return {
        newTerm: null,
        newStateCode: null,
        newSkill: null,
        newDegree: null,
        postFilter: null,
    }
  },
  computed: {
      ...mapState('post', {
        loading: 'loading',
        loaded: 'loaded',
        numJobs: 'qry_total',
        totalJobs: 'total_recs',
        qry: 'qry',
      }),
      currentPage() {
        return +this.$route.params.pageId;
      },
      filteredStateCodes() {
        return this.$store.getters['job/stateTotalsAlpha'] || [];
      },
      filteredSkills() {
        return this.$store.getters['job/skillTotalsAlpha'] || [];
      },
      filteredDegrees() {
        return this.$store.getters['job/degreeTotalsAlpha'] || [];
      },
      numPages() {
        return Math.ceil(this.numJobs / 25);
      },
      posts() {
        return this.$store.getters['job/getPageSet'](this.currentPage);
      },
      // posts: [],
  },

  watch: {
    // call again the method if the route changes
    '$route': function() { this.reqData(); },
  },

  created () {
    this.postFilter = JSON.parse(JSON.stringify(this.qry || {}));
    console.log('created postFilter', this.postFilter);

    this.reqData();
  },

  methods: {
    bookmark (postId) {
      console.log('bookmark post', postId);
    },
    clearFilter () {
      this.postFilter = {
        field: null,
        val: null,

        kw: null,

        terms: [],
        skills: [],
        stateCodes: [],
        degrees: [],
      };
      this.$store.dispatch( 'job/' + jobModule.actionTypes.LoadRecordsPageRequest, { qry: this.postFilter, page_id: 1 } ).then( () => {
            // this.newTerm = null;
          });
    },
    removeTerm (term) {
      this.postFilter.terms = this.postFilter.terms.filter(x => x != term);
      this.$store.dispatch( 'job/' + jobModule.actionTypes.LoadRecordsPageRequest, { qry: this.postFilter, page_id: 1 } ).then( () => {
            // this.newTerm = null;
          });
    },
    addTerm () {
        console.log('add term ', this.newTerm);
        this.postFilter.terms.push(this.newTerm);
        this.$store.dispatch( 'job/' + jobModule.actionTypes.LoadRecordsPageRequest, { qry: this.postFilter, page_id: 1 } ).then( () => {
            this.newTerm = null;
          });
    },
    addStateCode () {
      this.postFilter.stateCodes.push(this.newStateCode);
      this.$store.dispatch( 'job/' + jobModule.actionTypes.LoadRecordsPageRequest, { qry: this.postFilter, page_id: 1 } ).then( () => {
          this.newStateCode = null;
        });
    },
    removeStateCode (code) {
      this.postFilter.stateCodes = this.postFilter.stateCodes.filter(x => x != code);
      this.$store.dispatch( 'job/' + jobModule.actionTypes.LoadRecordsPageRequest, { qry: this.postFilter, page_id: 1 } ).then( () => {
            // this.newTerm = null;
          });
    },
    addSkill () {
        console.log('add skill ', this.newSkill);
        this.postFilter.skills.push(this.newSkill);
        this.$store.dispatch( 'job/' + jobModule.actionTypes.LoadRecordsPageRequest, { qry: this.postFilter, page_id: 1 } ).then( () => {
            this.newSkill = null;
          });
    },
    removeSkill (skill) {
      this.postFilter.skills = this.postFilter.skills.filter(x => x != skill);
      this.$store.dispatch( 'job/' + jobModule.actionTypes.LoadRecordsPageRequest, { qry: this.postFilter, page_id: 1 } ).then( () => {
            // this.newTerm = null;
          });
    },
    gotoPage ( /* pageId /* */) {
      console.log('goto called');
      // this.$router.push({ name: 'jobList', params: { pageId }});
    },
    reqData (force) {
      if (! this.loaded || force) {
        // this.$store.dispatch( 'job/' + PostActionTypes.PostLoadAllRequest );
      }
    },

  },
}
</script>



